import { renderIcon } from './RobinIcons';

export const RobinLoading = () => {
  return (
    <div className="flex justify-center items-center h-screen w-full bg-rf-gray-f9">
      <div className="relative inline-block ">
        {/* Logo */}
        {renderIcon('logo', 20)}

        {/* Spinner surrounding the logo */}
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="animate-spin duration-700 w-36 h-36 border-4 aspect-square border-r-rf-secondary border-rf-gray-75 rounded-full"></div>
        </div>
      </div>
    </div>
  );
};
